<template>
    <div>
        <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" v-if="visible">

                    <div class="modal-header">
                        <h6 class="modal-title">Groupe {{group.name}}</h6>
                        <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="" class="form-label">Propriétaire</label>
                            <input type="text" name="" id="" class="form-control form-control" disabled :value="group.owner_id.email" readonly>
                        </div>
                        <div class="mb-3">
                            <label for="" class="form-label">Membre(s) : {{group.members.length}}</label>
                            <div class="card p-2" style="height: 200px; overflow: auto;">
                                <ul class="list-group">
                                    <li class="list-group-item list-group-item-action" v-for="(member,index) in group.members" :key="member._id">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><i class="bi bi-person-circle me-2"></i></td>
                                                    <td class="w-100">{{member.email}}</td>
                                                    <td @click="removeUser(member,index)" v-if="!protectedGroup && !disabled"><i class="bi bi-x text-danger"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm" @click.prevent="close()"><i class="bi bi-x me-2"></i>Fermer</button>
                        <button type="button" class="btn btn-sm" @click="showImportModalClicked()"><i class="bi bi-download me-2"></i>Ajouter des utilisateurs</button>
                    </div>

                </div>
            </div>

        </div>
        
        <ImportBox modal-id="ImportBoxForGroup"
            :show="showImportModal"
            :title="titleImportForGroup"
            message="Veuillez saisir la liste des utilisateurs à ajouter à ce groupe. S'ils appartiennent à un autre groupe ils en seront retirés. Si un utilisateur n'existe pas il sera ignoré."
            no-small-modal="true"
            @close="showImportModalClose()"
            @import-clicked="(list) => importUsersToGroup(list)"
            />

        <ProgressBar modal-id="ProgressBarImport"
            :show="showProgress"
            is-undefined="true"
            :percent="progressPercent"
            message="Mise à jours du groupe en cours..."
            @close="() => importFinished()"
            />
    </div>

</template>

<style scoped>
    table tbody tr td {
        white-space: nowrap;
    }
</style>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";
    import axios from "axios";
    
    import ImportBox from './generics/ImportBox.vue';
    import ProgressBar from './generics/ProgressBar.vue';

    let props = defineProps(["modalId","show","userInfo","group","protectedGroup"]);
    let emits = defineEmits(["close","needReload","reloadSelectedGroup"]);
    let disabled = ref(false);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let showImportModal = ref(false);
    let titleImportForGroup = ref("");
    let showProgress = ref(false);
    let progressPercent = ref(0);

    onMounted( () => {
        disabled.value = props.protectedGroup;
    })

    function close(){
        emits("close");
    }

    function removeUser(member, index){
        disabled.value = true;
        axios.delete(`/api/users/${member._id}/group`)
            .then( answer => {
                emits("reloadSelectedGroup");
                disabled.value = false;
            })
            .catch( answer => {
                alert( (answer.response.data.message) ? answer.response.data.message : answer );
                disabled.value = false;
            })
    }

    function showImportModalClicked(){
        let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        myModal.hide();
        titleImportForGroup.value = `Ajouter à ${props.group.name}`;
        showImportModal.value = true;
    }
    
    function showImportModalClose(){
        let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        myModal.show();
        showImportModal.value = false;
    }

    async function importUsersToGroup(list){
        showImportModal.value = false;
        showProgress.value = true;
        let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        myModal.hide();
        try{
            await axios.post(`/api/groups/${props.group._id}/import`, {emails: list});
        }
        catch(e){
            progressPercent.value = 100;
            alert( (e.response.data.message) ? e.response.data.message : e );        
        }
        progressPercent.value = 100;

    }

    async function importFinished(){
        progressPercent.value = 0;
        showProgress.value = false;
        let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
        emits("needReload");
        close();
    }

</script>