<template>  
    <div>
        <h4 class="mb-3">
            Groupes
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button class="btn" @click="showAddGroup = true"><i class="bi bi-plus me-2"></i> Ajouter</button>
                <button class="btn" v-if="selectedGroups.length === 0" @click="selectedGroups = groups.filter( g => g.name !== 'Default group' ).map( g => g._id)"><i class="bi bi-check-square me-1"></i> Tous/Aucun</button>
                <button class="btn" v-if="selectedGroups.length > 0" @click="selectedGroups = []"><i class="bi bi-square me-1"></i> Tous/Aucun</button>
                <button class="btn text-danger" :disabled="selectedGroups.length === 0" @click="showConfirmTrash = true"><i class="bi bi-trash me-1"></i> Supprimer</button>
            </div>                
        </h4>
        
        <p style="text-align: justify;">Vous pouvez importer des utilisateurs dans un groupe. Soit les utilisateurs existent et sont alors simplement ajoutés/déplacés dans le groupe. Soit les utilisateurs n'existes pas et sont ignorés.</p>

        <!-- List of groups -->
        <ul class="list-group" v-if="loaded">
            <li class="list-group-item list-group-item-action" v-for="group in groups" :key="group._id">
                <table>
                    <tbody>
                        <tr>
                            <td role="button">
                                <input class="form-check-input me-2" type="checkbox" v-if="group.name !== 'Default group'" :value="group._id" v-model="selectedGroups" aria-label="...">
                                <input class="form-check-input me-2" type="checkbox" disabled v-else/>
                            </td>
                            <td>
                                <i class="bi bi-archive-fill me-2"></i>
                            </td>
                            <td class="w-100">
                                {{group.name}}
                                <em class="text-muted">
                                    <small>
                                        {{group.members.length}} membre(s)
                                        <span v-if="group.name === 'Default group'">{{ "(protected)" }}</span>
                                    </small>
                                </em>
                            </td>
                            <td @click="describeGroupClicked(group)" class="text-end" role="button" title="Voir le groupe et ses membres">
                                <i class="bi bi-arrows-angle-expand me-2"></i>
                            </td>
                            <td @click="assignGroupToProfilClicked(group)" class="text-end" role="button" title="Assigner tout les membres à un profil">
                                <i class="bi bi-ticket-perforated me-2"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
        </ul>
        <div v-else>
            <span class="spinner spinner-border spinner-border-sm me-2"></span> {{ (loadMessage) ? loadMessage : "Chargement..."}}
        </div>

        <!-- Error message -->
        <ErrorBox modal-id="ErrorBox"
            :show="error" 
            :message="error"
            @close="error = false" />
    
        <!-- InputBox to add a new Group -->
        <InputBox modal-id="InputBoxGroupName"
            :show="showAddGroup"
            title="Ajouter un nouveau groupe"
            message="Entrez un nom"
            @confirm="(name) => addGroup(name)"
            @close="showAddGroup = false"/>

        <!-- Confirm trash -->
        <ConfirmBox modal-id="DeleteConfirmBox"
            :show="showConfirmTrash" 
            message="Êtes vous certain de vouloir supprimer ces éléments ?"
            typeOf="question" 
            @close="showConfirmTrash = false" 
            @decline="showConfirmTrash = false" 
            @confirm="deleteGroups" />

        <!-- Group Describe Modal -->
        <GroupDescribe modal-id="GroupDescribeModal"
            :show="showGroupDescribe"
            :group="selectedGroup"
            :protectedGroup="protectedGroup"
            @close="showGroupDescribe = false" 
            @need-reload="groupDescribeAskForReload()"
            @reload-selected-group="reloadSelectedGroup()"/>

        <GroupAssignProfil modal-id="GroupAssignProfilModal"
            :show="showGroupAssignModal"
            :quotas="quotas"
            @close="showGroupAssignModal = false" 
            @confirm="(profil) => assignGroupToProfil(profil)" />

        <!-- Progress Bar -->
        <ProgressBar modal-id="progressBarGroup" 
            :show="showProgress"
            :percent="progressPercent"
            :message="progressMessage"
            @close="progressFinished()" />

    </div>
</template>

<style scoped>

    table tbody tr td{
        white-space: nowrap;
    }

</style>

<script setup>

    import {onMounted, ref, defineEmits, defineProps} from "vue"
    import axios from "axios";
    import $ from "jquery";

    import InputBox from "./components/generics/InputBox.vue";
    import ErrorBox from "./components/generics/ErrorBox.vue";
    import ConfirmBox from "./components/generics/ConfirmBox.vue";
    import GroupDescribe from "./components/GroupDescribe.vue";
    import ProgressBar from "./components/generics/ProgressBar.vue";
    import GroupAssignProfil from "./components/GroupAssignProfil.vue";
    
    let props = defineProps(["userInfo"]);
    
    let loaded = ref(false);
    let loadMessage = ref(false);
    let error = ref(false);

    let groups = ref([]);
    let quotas = ref([]);
    let selectedGroups = ref([]);
    let selectedGroup = ref(null);
    let protectedGroup = ref(false);

    let showAddGroup = ref(false);
    let showConfirmTrash = ref(false);
    let showGroupDescribe = ref(false);
    let showGroupAssignModal = ref(false);
    
    // For showing progress
    let showProgress  = ref(false);
    let progressMessage = ref("");
    let progressPercent = ref(0);
    
    onMounted( () => {
        load();
    })

    function load(){
        loadMessage.value = false;
        loaded.value = false;
        axios.get("/api/groups")
            .then( answer => {
                groups.value = answer.data;
                return axios.get("/api/quotas");
            })
            .then( answer => {
                quotas.value = answer.data;
                loaded.value = true;
            })
            .catch( answer => {
                error.value = (answer.response.data.message) ? answer.response.data.message : answer ;
                loaded.value = true;
            });
    }

    // Add new group
    async function addGroup(name){
        loaded.value = false;
        showAddGroup.value = false;
        axios.post("/api/groups", {name: name})
            .then( answer => {
                load();
            })
            .catch( answer => {
                error.value = (answer.response.data.message) ? answer.response.data.message : answer ;
                loaded.value = true;
            })
    }

    // Delete selected groups
    async function deleteGroups(){
        showConfirmTrash.value = false;
        showProgress.value = true;
        for(let i = 0; i < selectedGroups.value.length; i++){
            let groupId = selectedGroups.value[i];
            try{
                progressMessage.value = `Supression en cours (${i+1}/${selectedGroups.value.length})`;
                await axios.delete(`/api/groups/${groupId}`);  
            }
            catch(e){
                error.value = (e.response.data.message) ? e.response.data.message : e ;
                progressPercent.value = 100;
            }
            progressPercent.value = ( (i+1) / selectedGroups.value.length ) * 100;
        }
        progressMessage.value =`Action terminée !`;
    }

    async function assignGroupToProfilClicked(group){
        selectedGroup.value = group;
        showGroupAssignModal.value = true;
    }

    async function assignGroupToProfil(profil){
        showGroupAssignModal.value = false;
        showProgress.value = true;
        try{
            progressMessage.value = `Assignation en cours de ${selectedGroup.value.members.length}) membre(s)...`;
            await axios.post(`/api/groups/${selectedGroup.value._id}/assign-profil`, {profil_id: profil});  
        }
        catch(e){
            error.value = (e.response.data.message) ? e.response.data.message : e ;
            progressPercent.value = 100;
        }
        progressPercent.value = 100;
        progressMessage.value =`Action terminée !`;
    }

    // Show the describeGroup modal
    function describeGroupClicked(group){
        selectedGroup.value = group;
        if(group.name === 'Default group'){
            protectedGroup.value = true;
        }
        else{
            protectedGroup.value = false;
        }
        showGroupDescribe.value = true;
    }

    async function progressFinished(){
        showProgress.value = false;
        progressPercent.value = 0;
        load();
    }

    async function groupDescribeAskForReload(){
        showGroupDescribe.value = false;
        load();
    }

    async function reloadSelectedGroup(){
        load();
        selectedGroup.value = (await axios.get(`/api/groups/${selectedGroup.value._id}`)).data;
    }

</script>