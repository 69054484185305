<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title"><i class="bi bi-plus me-2"></i>{{(title) ? title : "Nouvelle entrée"}}</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <label for="" class="form-label">{{message}}</label>
                    <input type="text" name="" id="" class="form-control form-control-sm" v-model="inputValue">
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()"><i class="bi bi-x me-2 text-danger"></i>Annuler</button>
                    <button type="button" class="btn btn-sm" @click.prevent="confirm()"><i class="bi bi-check text-success me-2"></i>Ajouter</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let props = defineProps(["modalId","show","message","title"]);
    let emits = defineEmits(["close","confirm"]);

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    let inputValue = ref("");

    onMounted( () => {
        
    });

    function close(){
        inputValue.value = "";
        emits("close");
    }

    function confirm(){
        emits("confirm", inputValue.value);
    }


</script>