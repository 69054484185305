<template>
    <div class="modal fade" tabindex="-1" :id="modalId" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" v-if="visible">
                <div class="modal-header">
                    <h6 class="modal-title">Assigner à un profil</h6>
                    <button type="button" class="btn-close" @click.prevent="close()" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <p>Veuillez Choisir un profil auquel rattacher tous les utilisateurs du groupe. Pour assinger un utilisateur individuellement, veuillez l'éditer manuellement.</p>
                    <div class="mb-3">
                        <label for="" class="form-label">Profil</label>
                        <select name="" id="" class="form-select form-select-sm" v-model="selectedQuota" required>
                            <option v-for="quota in quotas" :key="quota._id" :value="quota._id">{{quota.name}}</option>
                        </select>
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button type="button" class="btn btn-sm" @click.prevent="close()"><i class="bi bi-x me-2"></i>Annuler</button>
                    <button type="button" class="btn btn-sm" @click.prevent="confirm()"><i class="bi bi-check text-success me-2"></i>Assigner</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { defineProps, defineEmits, onMounted, ref, computed } from 'vue';
    import Modal from "bootstrap/js/dist/modal";

    let props = defineProps(["modalId","show","quotas"]);
    let emits = defineEmits(["close","confirm"]);

    let selectedQuota = ref("");

    let visible = computed( () => {
        if(props.show){
            let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
            myModal.show();
        }
        else{
            try{
                let myModal = Modal.getOrCreateInstance(document.querySelector('#' + props.modalId));
                myModal.hide();
            }
            catch(e){
                return props.show;
            }
        }
        return props.show;
    });

    onMounted( () => {
        
    });

    function close(){
        emits("close");
    }

    function confirm(){
        emits("confirm",selectedQuota.value);
    }


</script>